/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '@lekoarts/gatsby-theme-cara/src/components/layout';
import MenuBar from '../../@lekoarts/gatsby-theme-cara/components/menu-bar';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import theme from '../../@lekoarts/gatsby-theme-cara/constants/theme.js';
import SEO from '@lekoarts/gatsby-theme-cara/src/components/seo';
import BlogBackground from '../../@lekoarts/gatsby-theme-cara/components/blog-background';
import GenericFooter from '../../@lekoarts/gatsby-theme-cara/components/generic-footer';
import { navigate } from 'gatsby-link';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useCheckoutStyles from '../../@lekoarts/gatsby-theme-cara/components/mixins-styles';
import encode from '../../@lekoarts/gatsby-theme-cara/components/encode';
import Subscribe from '../../@lekoarts/gatsby-theme-cara/components/subscribe';
import useScrollStart from '../../@lekoarts/gatsby-theme-cara/components/use-scroll-start';

const RockyRoad = ({ data }) => {
  // @ts-ignore
  const classes = useCheckoutStyles();
  const [state, setState] = React.useState({});
  const [checked, setChecked] = React.useState([]);
  const [subtotal, setSubtotal] = React.useState({ flat: 3699, monthly: 0 });
  const isScrolling = useScrollStart();

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleToggle = (value, cost) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    handleCostAdjustment(value, cost);
  };
  const handleCostAdjustment = (title, cost) => {
    if (checked.indexOf(title) === -1) {
      if (cost.search('/mo') !== -1) {
        cost = cost.replace('/mo', '');
        cost = cost.substring(1);
        setSubtotal({ ...subtotal, monthly: subtotal.monthly + Number(cost) });
      }
    } else {
      if (cost.search('/mo') !== -1) {
        cost = cost.replace('/mo', '');
        cost = cost.substring(1);
        setSubtotal({ ...subtotal, monthly: subtotal.monthly - Number(cost) });
      } else {
        setSubtotal({ ...subtotal, flat: 3699 });
      }
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  const mixins = [
    { title: 'Additional Pages', cost: '$100 (Max 5)' },
    { title: 'Blog', cost: '$15/mo' },
    { title: 'Event Page', cost: '$15/mo' },
    { title: 'Photo Gallery', cost: '$13/mo' },
    { title: 'Analytics', cost: '$15/mo' },
    { title: 'Google Friendly Sitemap', cost: '$1/mo' },
    { title: 'Site Updates (Recommended)', cost: '$35/mo' },
  ];
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Rocky Road | Online Ordering" />
      <Layout className="Blog-post-page">
        {/* <Subscribe initiallyOpen={true} /> */}
        <MenuBar />
        <BlogBackground offset={0} />
        <div className="Pricing-content text-center">
          <Container component="main" maxWidth="sm">
            <h2
              sx={{
                fontSize: ['24px', '32px', '48px'],
                textAlign: 'center',
              }}
            >
              Rocky Road
            </h2>
            <Img
              className={isScrolling && 'Pricing-image'}
              fixed={data.image1.childImageSharp.fixed}
              alt="rocky road ice cream cone"
            />
            <br />
            <br />
            <p>We will get back to you within 24 hours after submission.</p>
            <form
              className={classes.form}
              name="RockyRoad"
              method="post"
              action="/thankyou"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="RockyRoad" />
              <p hidden>
                <label>
                  Fill me out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>
              <div className={classes.mixins}>
                <h3>Mix-Ins</h3>
                <p>Interested in any additional functionality?</p>
                <hr />
                <List className={classes.list} dense component="div" role="list">
                  {mixins.map(({ title, cost }) => {
                    const labelId = `item-${title}-label`;

                    return (
                      <div key={title}>
                        <ListItem
                          role="listitem"
                          button
                          onClick={handleToggle(title, cost)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              checked={checked.indexOf(title) !== -1}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              color="primary"
                              name={title}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={title} />
                          <ListItemText className={classes.cost} primary={cost} />
                        </ListItem>
                        {title === 'Additional Pages' &&
                          checked.indexOf('Additional Pages') !== -1 && (
                            <ListItem role="listitem" button>
                              <TextField
                                name="numpages"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                id="numpages"
                                type="number"
                                label="How Many?"
                                inputProps={{ min: 1, max: 5, step: 1 }}
                                onChange={e => {
                                  setSubtotal({
                                    ...subtotal,
                                    flat: 3699 + 100 * Number(e.target.value),
                                  });
                                  handleChange(e);
                                }}
                              />
                            </ListItem>
                          )}
                      </div>
                    );
                  })}
                  <ListItem />
                </List>
              </div>
              <h3>
                Estimate: ${subtotal.flat.toLocaleString()}
                {subtotal.monthly > 0 && <span> and ${subtotal.monthly}/month</span>}
              </h3>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    type="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="phone"
                    name="phone"
                    variant="outlined"
                    required
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    type="phone"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" name="solicit" />
                    }
                    label="I want to receive marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit Order
              </Button>
            </form>
          </Container>
        </div>
        <GenericFooter />
        <form
          name="RockyRoad"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          hidden
        >
          <input type="text" name="company" />
          <input type="text" name="firstName" />
          <input type="text" name="lastName" />
          <input type="email" name="email" />
          <input type="phone" name="phone" />
          <textarea name="message" />
          <input type="checkbox" name="solicit" />
        </form>
      </Layout>
    </ThemeProvider>
  );
};

export default RockyRoad;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "RockyRoad.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
